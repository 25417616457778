.Statement_wrapper {
  max-width: 880px;
  width: 100%;
  &.Statement_wrapper__expired {
    .Statement_invoice-status {
      background: rgba(255, 85, 109, 0.1);
      color: #FF556D;
    }
  }
  .Statement_invoice-status {
    box-shadow: 0px 18px 34px rgba(34, 39, 47, 0.1);
    border-radius: 2px;
    background: rgba(90, 188, 103, 0.1);
    width: 100%;
    min-height: 36px;
    color: #5ABC67;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Statement_amount {
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    margin-bottom: 20px;
  }
  .Statement_subtitle {
    font-size: 10px;
    line-height: 12px;
    opacity: 0.44;
    text-transform: uppercase;
    margin: 0 0 8px 0;
    font-weight: 400;
  }
  .Statement_inner {
    max-width: 254px;
    width: 100%;
    margin: 50px auto 0 auto;
    min-height: 216px;
    background: linear-gradient(109.32deg, #2D4D70 27.32%, #213B57 54.09%, #14273C 98.91%);
    box-shadow: 0px 18px 21px rgba(34, 39, 47, 0.34);
    border-radius: 4px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 30px;
    z-index: 1;
    &-title {
      color: #2AF3F3;
      margin: 0 0 20px 0;
    }
    &-border {
      position: absolute;
      right: 10px;
      top: 10px;
      left: 10px;
      bottom: 10px;
      border-radius: 2px;
      border: 1px solid #2AF3F3;
      opacity: .34;
      pointer-events: none;
    }
    &::before {
      content: '';
      background: linear-gradient(109.32deg, #2D4D70 27.32%, #213B57 54.09%, #14273C 98.91%);
      opacity: 0.2;
      border-radius: 4px;
      width: 215px;
      height: 226px;
      position: absolute;
      top: -20px;
      pointer-events: none;
      z-index: -1;
    }
    &::after {
      content: '';
      background: linear-gradient(109.32deg, #2D4D70 27.32%, #213B57 54.09%, #14273C 98.91%);
      opacity: 0.2;
      border-radius: 4px;
      width: 234px;
      height: 226px;
      position: absolute;
      top: -10px;
      pointer-events: none;
      z-index: -1;
    }
  }
  .Statement_title {
    color: #fff;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 21px;
    line-height: 25px;
    margin: 50px 0 0 0;
  }

  .Statement_border {
    max-width: 880px;
    width: 100%;
    height: 1px;
    background: #2af3f3;
    opacity: 0.1;
    border-radius: 4px;
    margin: 20px 0 30px 0;
    &-bottom {
      margin: 30px 0 0 0;
    }
  }
}